<template>
  <b-container class="pt-10">
    <b-row>
      <span
        class="spinner-border spinner-border-lg center-spinner"
        v-if="loading"
      ></span>
      <b-col v-else xl="4" lg="5" md="8" sm="10" class="ms-auto me-auto">
        <b-card class="card-sso mt-5">
          <b-card-body v-if="code">
            <div v-if="!loading" class="d-flex justify-content-between">
              <b-button @click="signOut">{{
                $t('sso.button.signout')
              }}</b-button>
              <b-button v-if="!error" @click="fetchTokens" variant="primary">
                {{ $t('sso.button.continue') }}
              </b-button>
            </div>
          </b-card-body>
          <b-card-footer
            v-if="!code && organization"
            class="d-flex justify-content-around border-0"
          >
            <div v-if="loading">
              <div class="d-flex justify-content-center">
                <span class="spinner-border spinner-border text-primary"></span>
              </div>
            </div>
            <b-button v-if="!loading" @click="signInUsingSSO" variant="primary">
              {{ $t('sso.button.login') }}
            </b-button>
          </b-card-footer>
        </b-card>
        <b-alert variant="danger" :show="true" v-if="error" class="mt-5 py-2">
          {{ error }}
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Amplify from 'aws-amplify'
import { Credentials, StorageHelper } from '@aws-amplify/core'
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken
} from 'amazon-cognito-identity-js'

export default {
  name: 'SSO',
  components: {},
  data() {
    return {
      organization: null,
      error: null,
      loading: false,
      code: null,
      state: null
    }
  },
  mounted() {
    const autologin = true

    this.organization =
      (this.$route.query && this.$route.query.o) ||
      localStorage.organizationName
    if (this.organization) {
      localStorage.organizationName = this.organization
      if (this.$route.query.error_description) {
        this.error = this.$route.query.error_description
      } else if (this.$route.query.code) {
        this.code = this.$route.query.code
        this.state = this.$route.query.state
        if (autologin) {
          this.fetchTokens()
        }
      } else {
        if (autologin) {
          this.clearLoginSession()
          this.signInUsingSSO()
        }
      }
    } else {
      this.error = 'Missing organization name'
    }
  },
  methods: {
    clearLoginSession() {
      // clear session first
      Amplify.Auth.signOut()
    },
    signOut() {
      Amplify.Auth.signOut().then(() => {
        this.code = null
        this.state = null
        window.location = '/#/loggedout'
      })
    },
    createCognitoUser(username) {
      const userData = {
        Username: username,
        Pool: new CognitoUserPool({
          UserPoolId: JSON.parse(localStorage.orgData).userPoolId,
          ClientId: JSON.parse(localStorage.orgData).userPoolWebClientId
        })
      }
      userData.Storage = new StorageHelper().getStorage()
      const user = new CognitoUser(userData)
      return user
    },
    fetchTokens() {
      const orgData = JSON.parse(localStorage.orgData)
      if (!orgData.identityFederation) {
        this.error = this.$t('setup.error.SSONotFound')
      } else {
        const pkceKey = window.sessionStorage.getItem('ouath_pkce_key')
        const clientId = JSON.parse(localStorage.orgData).userPoolWebClientId
        const callbackUri = encodeURIComponent(
          orgData.identityFederation.callbackUrl
        )
        const orgName = localStorage.organizationName
        const path = `/code/${this.code}/${clientId}/${callbackUri}/${pkceKey}/${orgName}`
        this.loading = true
        Amplify.API.get('PublicAPI', path, {})
          .then(async (result) => {
            const session = new CognitoUserSession({
              IdToken: new CognitoIdToken({ IdToken: result.id_token }),
              RefreshToken: new CognitoRefreshToken({
                RefreshToken: result.refresh_token
              }),
              AccessToken: new CognitoAccessToken({
                AccessToken: result.access_token
              })
            })

            await Credentials.set(session, 'session')
            const currentUser = this.createCognitoUser(
              session.getIdToken().decodePayload()['cognito:username']
            )
            currentUser.setSignInUserSession(session)
            // this.loading = false
            if (
              currentUser &&
              currentUser.signInUserSession.idToken.payload.pulsUsername
            ) {
              this.$router.push('/')
            } else {
              this.loading = false
              this.error = this.$t('sso.noUserAccountFound')
            }
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            if (e.response?.data?.error) {
              this.error = this.$t('sso.error.generic', {
                error: e.response.data.error
              })
            } else {
              this.error = e.message
            }
            this.loading = false
            // this.signOut()
          })
      }
    },
    signInUsingSSO() {
      this.loading = true
      let providerName // , domain
      Amplify.API.get('PublicAPI', '/organizations/' + this.organization, {})
        .then((orgResult) => {
          const authConfig = {
            identityPoolId: orgResult.identityPoolId,
            region: 'eu-west-1',
            userPoolId: orgResult.userPoolId,
            userPoolWebClientId: orgResult.userPoolWebClientId,
            mandatorySignIn: true,
            clientMetadata: {
              organizationName: this.organization,
              organizationFullName: orgResult.organizationFullName,
              locale: 'en' // TODO: Use some kind of default
            }
          }
          if (orgResult?.identityFederation?.identityProviders?.length) {
            providerName =
              orgResult.identityFederation.identityProviders.length === 1
                ? orgResult.identityFederation.identityProviders[0]
                : this.$route.query.provider || null
            const oauth = {
              domain: orgResult.identityFederation.domain,
              scope: orgResult.identityFederation.scopes,
              redirectSignIn: orgResult.identityFederation.callbackUrl,
              redirectSignOut: orgResult.identityFederation.logoutUrl,
              responseType: 'code'
            }
            // domain = oauth.domain
            authConfig.oauth = oauth
          } else {
            throw new Error(this.$t('setup.error.SSONotFound'))
          }
          localStorage.orgData = JSON.stringify(orgResult)
          Amplify.configure({
            Auth: authConfig
          })
        })
        .then(() => {
          // console.log(`Performing federated signin via ${providerName}`)
          if (providerName) {
            return Amplify.Auth.federatedSignIn({ provider: providerName })
          } else {
            return Amplify.Auth.federatedSignIn()
          }
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 404) {
            this.error = this.$t('setup.error.SSONotFound')
          } else {
            // eslint-disable-next-line no-console
            console.error(error)
            this.error = error
          }
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.center-spinner {
  margin: 0 auto !important;
  float: none !important;
}
</style>
